import { Button, Dropdown, Menu } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

export const PrintCreditDebitNote = ({ orderDetails, type, disabled }: any) => {
	const menu = (
		<Menu>
			{orderDetails?.creditDebitNotes?.map((item: any, index: number) => {
				return (
					<Menu.Item>
						<Button
							type="text"
							size="middle"
							target="_blank"
							style={{
								width: '100%',
								textAlign: 'left',
								color: '#1890ff',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
							rel="noreferrer"
							href={`https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/${item.creditNoteUrl}`}
						>
							Credit Note {index + 1} <LinkOutlined />
						</Button>
					</Menu.Item>
				);
			})}
		</Menu>
	);

	return (
		<Dropdown overlay={menu} disabled={disabled} placement="bottomLeft">
			<Button type="dashed" size="small" style={{ width: '100%', color: 'black' }}>
				{type} Notes
			</Button>
		</Dropdown>
	);
};
