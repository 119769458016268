import Icon from '@ant-design/icons';
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Modal,
	notification,
	Popconfirm,
	Popover,
	Row,
	Select,
	Tag,
	Timeline,
	Tooltip,
	Typography
} from 'antd';
import { groupBy } from 'lodash';
import React, { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { apiOms } from 'shared/api';
import { fetchOrderInfo, getPincodeDetails, setReadyToShip, updateOrderCustomerAddress, updateOrderDetails } from 'shared/api/orders';
import { Loading } from 'shared/components/Loading';
import envelopeIcon from 'shared/svgs/envelope';
import phoneIcon from 'shared/svgs/phone';
import { sortByDate } from 'shared/utils/date';
import errorHandler from 'shared/utils/errorHandler';
import { getTrackingUrl } from 'utils/delivery/getTrackingUrl';
import PackageModal from '../../../app/AppSeller/SettingsPage/ShippingPackages/components/PackageModal';
import { FulfillmentProviders, hyperLocalPartners, ORDER_STATUS, PAYMENT_METHOD, SHOP_TYPE } from '../../../constants';
import { AuthContext } from '../../contexts/Auth';
import { SocketContext } from '../../contexts/SocketContext';
import { ReactComponent as CloseIcon } from '../../svgs/closeIcon.svg';
import { FormLayout } from '../FormLayout';
import { InfoSlider } from '../InfoSlider/InfoSlider';
import { InfoPopOver } from '../PopOver/InfoPopOver';
import { PrintCreditDebitNote } from '../printCreditDebitNote/PrintCreditDebitNote';
import { PrintInvoiceDialog } from '../PrintInvoiceDialog';
import { PrintLabelDialog } from '../PrintLabelDialog';
import { ChatUi } from './components/ChatUi/ChatUi';
import { ISocketConstant } from './components/ChatUi/constant';
import { CreditDebitNoteModal } from './components/CreditDebitNote/CreditDebitNoteModal';
import OrderActions from './components/OrderActions/OrderActions';
import { OrderTrail } from './components/OrderTrail';
import { OrderWeightChange } from './components/OrderWeightChange/OrderWeightChange';
import { ReassignWarehouse } from './components/ReassignWarehouse/ReassignWarehouse';
import { SelectDropDown } from './components/SelectDropdown/SelectDropDown';
import styles from './index.module.scss';
import { addIndexInTrail, customerDetailsForm } from './utils';
import { UpdateItemsQtyModal } from './components/ItemQty/ItemQtyUpdateModal';
import dayjs from 'dayjs';
const GoogleMapComponent = React.lazy(() => import('../GoogleMap/HyperLocalMap'));

export interface IOrderInfoSlider {
	setReloadOrders?: any;
	onClose?: () => void;
	orderId?: any;
	companyId?: string;
	showCancelButton?: boolean;
	reloadList?: () => void;
	showReassignWarehouse?: boolean;
	showUpdateWeight?: boolean;
	warehouses?: any;
	isAdminPanel?: boolean;
}
export function OrderInfoSlider({
	setReloadOrders,
	onClose,
	orderId,
	companyId,
	showCancelButton = false,
	reloadList = function () {
		return;
	},
	showReassignWarehouse = false,
	showUpdateWeight = false,
	warehouses = [],
	isAdminPanel = false
}: Readonly<IOrderInfoSlider>) {
	const isMounted = useRef(false);
	const [loading, setLoading] = useState(false);
	const [isReadyToShipButtonLoading, setIsReadyToShipButtonLoading] = useState(false);
	const [reloadOrder, setReloadOrder] = useState<any>(0);
	const [data, setData] = useState<any>(null);
	const [isDeliveredButtonLoading, setIsDeliveredButtonLoading] = useState(false);
	const [isCancelOrderButtonLoading, setIsCancelOrderButtonLoading] = useState(false);
	const [isCustomerFormVisible, setIsCustomerFormVisible] = useState(false);
	const [isEditFormVisibility, setIsEditFormVisibility] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [reverseTrail, setReverseTrail] = useState<any[]>([]);
	const [forwardTrail, setForwardTrail] = useState<any[]>([]);
	const [shippingPackageCodes, setShippingPackageCodes] = useState<any[] | null>([]);
	const [collapsableForwardTrail, setCollapsableForwardTrail] = useState<any[]>([]);
	const [collapsableReverseTrail, setCollapsableReverseTrail] = useState<any[]>([]);
	const [isCancelOrderLoading, setIsCancelOrderLoading] = useState(false);
	const [whatsAppMessages, setWhatsAppMessages] = useState<any[]>([]);
	const [isWhatsappChatVisible, setIsWhatsappChatVisible] = useState(false);
	const [shipperIdOfAggregator, setShipperIdOfAggregator] = useState(null);
	const [isPackageModalVisible, setIsPackageModalVisible] = useState(false);
	const [isCreditDebitNodeModalVisible, setIsCreditDebitNodeModalVisible] = useState(false);
	const [isUpdateItemsQtyModalVisible, setIsUpdateItemsQtyModalVisible] = useState(false);
	const [dueDate, setDueDate] = useState<string | null>('');
	const [cancellationReason, setCancellationReason] = useState<string>('');

	const [reloadCount, setReloadCount] = useState(1);
	const socketIo = useContext(SocketContext);
	const { authState } = useContext(AuthContext);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		setIsEditFormVisibility(false);
		fetchOrderDetails();
		fetchShippingPackageType();
	}, [orderId, reloadOrder, reloadCount]);

	useEffect(() => {
		if (data) {
			getTrail();
		}
	}, [data]);

	useEffect(() => {
		if (forwardTrail.length) {
			const sortedDate = sortByDate({ data: forwardTrail, fieldName: 'createdAt' });
			const indexedTrail = addIndexInTrail(sortedDate);
			const updatedTrail = Object.values(groupBy(indexedTrail, 'index'));
			setCollapsableForwardTrail(updatedTrail);
		}
		if (reverseTrail.length) {
			const sortedDate = sortByDate({ data: reverseTrail, fieldName: 'createdAt' });
			const indexedTrail = addIndexInTrail(sortedDate);
			const updatedTrail = Object.values(groupBy(indexedTrail, 'index'));
			setCollapsableReverseTrail(updatedTrail);
		}
	}, [forwardTrail, reverseTrail]);

	function getTrail() {
		setReverseTrail(data.trails.filter((T: any) => T.return));
		setForwardTrail(data.trails.filter((T: any) => !T.return));
	}
	async function fetchOrderDetails() {
		try {
			setLoading(true);
			// For wms panel companyId will not be null
			const { data } = await fetchOrderInfo(orderId, companyId);
			socketIo.emit(ISocketConstant.RESPONSE, { orderId, authId: authState.oauthCode });
			if (!isMounted.current) return;
			setData(data.order);
		} catch (error) {
			if (!isMounted.current) return;
			notification.error({
				message: 'Server Error',
				description: 'Please try again after some time.',
				placement: 'topRight'
			});
			/**
			 * this should be undefined as by default it is null,
			 * and we need to differentiate between initial and
			 * no data found state
			 */
			setData(undefined);
		} finally {
			isMounted.current && setLoading(false);
		}
	}
	async function fetchShippingPackageType() {
		try {
			// For wms panel companyId will not be null
			const {
				data: { data }
			} = await apiOms.get(`/shipping-packages`);
			setShippingPackageCodes(data.filter((item: any) => item.active).map((e: any) => e.code));
		} catch (error) {
			if (!isMounted.current) return;
			notification.error({
				message: 'Server Error',
				description: 'Please try again after some time.',
				placement: 'topRight'
			});
			setShippingPackageCodes(null);
		}
	}

	async function onClickReadyToShip() {
		try {
			setIsReadyToShipButtonLoading(true);
			await setReadyToShip(orderId, shipperIdOfAggregator);
			if (!isMounted.current) return;
			notification.success({
				message: 'Order Updated',
				description: data.message,
				placement: 'topRight'
			});
			setReloadOrder((v: any) => v + 1);
		} catch (err) {
			if (!isMounted.current) return;
			errorHandler(err);
		} finally {
			isMounted.current && setIsReadyToShipButtonLoading(false);
			fetchOrderDetails();
		}
	}

	const markOrderAsDelivered = async (values?: any) => {
		const key = PAYMENT_METHOD.CREDIT;
		try {
			const { data } = await apiOms.patch(`/orders/${orderId}/mark-delivered`, {
				status: ORDER_STATUS.DELIVERED,
				...(((dueDate != null && dueDate.length > 0) || values?.[key]) && {
					paymentMode: PAYMENT_METHOD.CREDIT,
					d2RDueDate: dueDate || values?.key
				})
			});
			if (data?.status) {
				message.success('Order marked as delivered!');
			}
			setReloadOrder((v: any) => v + 1);
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsDeliveredButtonLoading(false);
			fetchOrderDetails();
		}
	};

	const showCancelOrderButton = () => {
		const { status, shopType, isD2ROrder } = data;

		// delivered order can only be marked cancelled if it is
		// a) D2R order, b) marked by super admins
		if (status === ORDER_STATUS.DELIVERED && isAdminPanel && isD2ROrder) {
			return true;
		}

		return (
			Boolean(status === ORDER_STATUS.PLACED && [SHOP_TYPE.MANUAL, SHOP_TYPE.MANUAL_FBW].includes(shopType)) ||
			(showCancelButton &&
				[
					ORDER_STATUS.PLACED,
					ORDER_STATUS.PROCESSED,
					ORDER_STATUS.READY_TO_SHIP,
					ORDER_STATUS.SHIPPED,
					ORDER_STATUS.IN_TRANSIT,
					ORDER_STATUS.OUT_FOR_DELIVERY
				].includes(status))
		);
	};

	const handleCancelOrder = async () => {
		try {
			setIsCancelOrderButtonLoading(true);

			const queryParams = showCancelButton ? `?companyId=${companyId}` : '';
			await apiOms.patch(`/orders/${orderId}/cancel${queryParams}`, { reason: cancellationReason });

			setReloadOrder((prevValue: any) => prevValue + 1);
			reloadList();
			message.success('order cancelled successfully!');
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsCancelOrderButtonLoading(false);
			fetchOrderDetails();
		}
	};

	const onCustomerDetailsSubmit = async (values: any) => {
		try {
			await updateOrderCustomerAddress(data.id, values);
			notification.success({
				message: 'Update Order',
				description: 'updated successfully.',
				placement: 'topRight'
			});
		} catch (error) {
			console.log('!!!!!!Error printed here for onCustomerDetailsSubmit!!!!!!!!!', error);
			errorHandler(error);
		} finally {
			setIsCustomerFormVisible(false);
			fetchOrderDetails();
		}
	};

	const onEditOrderDetailSubmit = async (values: any) => {
		try {
			await updateOrderDetails(data.id, values);
			notification.success({
				message: 'Update Order',
				description: 'updated successfully.',
				placement: 'topRight'
			});
		} catch (error) {
			console.log('!!!!!!Error printed here for onEditOrderDetailSubmit!!!!!!!!!', error);
			errorHandler(error);
		} finally {
			setIsEditFormVisibility(false);
			fetchOrderDetails();
		}
	};

	const onReturnItem = async () => {
		try {
			for (let item of data.items) {
				if (item.returnQuantity > item.quantity) {
					errorHandler(new Error('Return quantity cannot be greater than actual'));
					return;
				}
			}
			data.returnOrderItems = data.items.filter((item: any) => item.returnQuantity > 0);
			if (data.returnOrderItems.length === 0) {
				errorHandler(new Error('Return quantity cannot be zero'));
				return;
			}
			setLoading(true);
			const res = await apiOms.post('/schedule_dto', data);

			notification.success({ message: res.data.message });
			fetchOrderDetails();
			setIsModalVisible(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);

			errorHandler(error);
		}
	};

	const cancelReturn = async () => {
		try {
			setLoading(true);

			const response = await apiOms.post('/cancel-return', {
				splitOrderId: data.id,
				provider: data.returnPartnerId,
				awb: data.returnAwb
			});
			if (response.data.status === false) notification.error({ message: response.data.message });
			fetchOrderDetails();
			notification.success({ message: response.data.message });
			setLoading(false);
		} catch (error) {
			setLoading(false);

			errorHandler(error);
		}
	};

	const onPincodeChange = useCallback(async ({ target: { value } }: any, form: any) => {
		try {
			if (String(value).length !== 6) {
				return;
			}
			let {
				data: { warehousePinDetails }
			} = await getPincodeDetails(value);

			if (warehousePinDetails[0]) {
				// automatically populate city and state based on pincode
				form.setFieldsValue({
					state: String(warehousePinDetails[0]['state'] || warehousePinDetails[0]['State']).replace(/"/g, ''),
					city: String(warehousePinDetails[0]['district'] || warehousePinDetails[0]['District']).replace(/"/g, '')
				});
				return;
			}
			return;
		} catch (error) {
			errorHandler(error);
		}
	}, []);

	const updateOrderPackage = async (orderId: any, selectedValue: any) => {
		try {
			setLoading(true);
			const { data } = await apiOms.patch(`/order-shipping-packages`, { orderId, selectedValue });
			if (data.status) {
				message.success('Shipping package successfully changed');
			}
		} catch (error) {
			message.error('Server Error');
		} finally {
			setLoading(false);
		}
	};

	const onCancelOrder = async () => {
		try {
			setIsCancelOrderLoading(true);
			const { data: response } = await apiOms.patch(`/orders/${orderId}/cancel`);
			if (response.status) {
				message.success('Shipping package successfully changed');
			}
		} catch (err) {
			errorHandler(err);
		} finally {
			setIsCancelOrderLoading(false);
		}
	};

	const handleUnBlockInventory = async () => {
		try {
			setIsCancelOrderLoading(true);
			const { data: response } = await apiOms.patch(`/orders/unblock-inventory/${orderId}`);
			if (response.status) {
				message.success('Inventory unblocked');
			}
		} catch (err) {
			errorHandler(err);
		} finally {
			setReloadOrder((e: any) => e + 1);
			setIsCancelOrderLoading(false);
		}
	};
	const customerFormMemoized = useMemo(() => customerDetailsForm(onPincodeChange), [onPincodeChange]);

	useEffect(() => {
		socketIo.on(ISocketConstant.ORDER_MESSAGE, (data: any) => {
			if (data) {
				setWhatsAppMessages(data);
			}
		});
		socketIo.on(ISocketConstant.MESSAGE_UPDATE, (data: any) => {
			setWhatsAppMessages((prev) => [...prev, data]);
		});
		socketIo.on(ISocketConstant.STATUS_CHANGE, (data: any) => {
			const updatedStatusMessages = whatsAppMessages.map((e) => {
				if (e.id === data.splitOrderId) {
					return {
						...e,
						status: data.status
					};
				}
				return e;
			});
			setWhatsAppMessages(updatedStatusMessages);
		});
	}, []);

	const renderEditOrderDetail = () => {
		if (isEditFormVisibility && (data.shopType === SHOP_TYPE.MANUAL_FBW || data.shopType === SHOP_TYPE.MANUAL)) {
			return (
				<FormLayout
					formTitle={'Edit Order Details'}
					onSave={(values: any) => onEditOrderDetailSubmit(values)}
					onClose={() => setIsEditFormVisibility(false)}
					formFields={[
						{
							name: 'awb',
							id: 'awb',
							label: 'AWB(optional)',
							required: false,
							placeholder: 'Enter AWB'
						},
						{
							name: 'remarks',
							id: 'remarks',
							label: 'Remarks',
							required: true,
							placeholder: 'Enter Remarks'
						}
					]}
					initialValues={{
						awb: data.deliveryAwb,
						remarks: data.remarks
					}}
				/>
			);
		}

		if (isCreditDebitNodeModalVisible) {
			return (
				<CreditDebitNoteModal
					setReloadOrder={setReloadOrder}
					setIsCreditDebitNodeModalVisible={setIsCreditDebitNodeModalVisible}
					order={data}
				/>
			);
		}

		if (isUpdateItemsQtyModalVisible) {
			return <UpdateItemsQtyModal order={data} isModalVisible={setIsUpdateItemsQtyModalVisible} setReloadOrder={setReloadOrder} />;
		}

		if ((data.shopType === SHOP_TYPE.MANUAL_FBW || data.shopType === SHOP_TYPE.MANUAL) && data.status === ORDER_STATUS.PLACED) {
			return (
				<Row className={styles.row4}>
					<Col span={24} style={{ marginBottom: 5 }}>
						<Row>
							<Col span={18}>
								<span className="title">Order Details</span>
							</Col>
							<Col className={styles.icon} span={6}>
								<Button
									type="primary"
									size="small"
									style={{ width: '60%' }}
									onClick={(e) => {
										e.stopPropagation();
										setIsEditFormVisibility(true);
									}}
								>
									Edit
								</Button>
							</Col>
						</Row>
					</Col>
					<Col span={24} className="sub">
						{data.shopType !== SHOP_TYPE.MANUAL && (
							<Row className="row4-row">
								<Col span={8}>
									<span style={{ fontWeight: 'bold' }}>AWB Number: </span>
								</Col>
								<Col span={16} className="right-text ellipsis">
									<span>{data?.deliveryAwb}</span>
								</Col>
							</Row>
						)}

						<Row className="row4-row">
							<Col span={24}>
								<span style={{ fontWeight: 'bold', marginBottom: '5px' }}>Remarks: </span>
							</Col>
							<Col span={24} className="right-text ellipsis">
								<span>{data?.remarks || 'No Remarks Found'}</span>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		}

		return null;
	};

	return (
		<InfoSlider onClose={onClose}>
			<div className={styles.main} style={reverseTrail?.length === 0 ? { width: '24rem' } : { width: '28rem' }}>
				{loading && <Loading loading={loading} />}
				<CloseIcon title="Close" className="closeBtn" onClick={onClose} />
				{data ? (
					<Col className={styles.orderInfoContainer}>
						<Row className={styles.paddedRow}>
							<Col span={24}>
								<Row className={styles.row1}>
									<Col span={24}>
										<span className="title">{`Order ${data.shopOrderId || data.externalOrderId}`}</span>
										{data.ewayBill ? <div>E-WayBill: {data.ewayBill}</div> : ''}
									</Col>
									{data?.status === 'PLACED' ? null : (
										<Row>
											<Col span={24}>
												<span className="sub">
													AWB:{' '}
													<a
														target="_blank"
														rel="noreferrer"
														href={getTrackingUrl(FulfillmentProviders.WHEREHOUSE_LIGHTNING, data.deliveryAwb)}
													>
														{data.deliveryAwb}
													</a>
												</span>
												<div>
													{data.rtsVerifiedAt
														? `Verified on: ${dayjs(data.rtsVerifiedAt).format('YYYY/MM/DD hh:mm A')}`
														: ''}
												</div>
												{data.paymentMode === PAYMENT_METHOD.COD &&
													data.status === ORDER_STATUS.DELIVERED &&
													data.remittanceStatus !== null && (
														<div style={{ margin: '0.5rem 0' }}>
															<Tag color="blue">
																COD Remittance Status:{' '}
																{String(data?.remittanceStatus?.status).toUpperCase()}
															</Tag>
															<InfoPopOver description="Possible values: PROCESSING, DUE, PAID. PROCESSING:  Order is delivered and system is waiting for 3pl partner to remit the amount to Wherehouse. DUE: COD has been remitted to Wherehouse account and  Wherehouse team is in the process of remitting it forward to brand's account. PAID: COD amount has been successfully remitted to brand's account." />
														</div>
													)}
											</Col>
										</Row>
									)}
									{data.returnAwb ? (
										<Col span={24}>
											<span className="sub">
												Return AWB:-{' '}
												<a
													target="_blank"
													rel="noreferrer"
													href={getTrackingUrl(data.returnPartnerId, data.returnAwb)}
												>
													{data.returnAwb}
												</a>
											</span>
										</Col>
									) : null}
								</Row>

								<OrderActions
									setIsWhatsappChatVisible={setIsWhatsappChatVisible}
									isWhatsappChatVisible={isWhatsappChatVisible}
									orderId={orderId}
									setReloadOrders={setReloadOrders}
									setIsCreditDebitNodeModalVisible={setIsCreditDebitNodeModalVisible}
									isD2rOrder={Boolean(data?.isD2ROrder && data?.status === ORDER_STATUS.DELIVERED)}
									isAdminPanel={isAdminPanel}
									isCDNAlreadyGenerated={Boolean(data?.creditDebitNote)}
									setUpdateItemsQtyModal={setIsUpdateItemsQtyModalVisible}
									order={data}
								/>

								{renderEditOrderDetail()}
								<div>
									<Row className={styles.row4}>
										{showUpdateWeight && data.status === ORDER_STATUS.PLACED && <OrderWeightChange data={data} />}
										{showReassignWarehouse && data.status === ORDER_STATUS.PLACED && (
											<ReassignWarehouse
												warehouses={warehouses}
												order={data}
												reloadList={reloadList}
												reloadOrder={setReloadOrder}
											/>
										)}
										{whatsAppMessages?.length > 0 && isWhatsappChatVisible && (
											<>
												<strong style={{ marginBottom: '0.5rem' }}>Whatsapp messages</strong>
												<ChatUi message={whatsAppMessages} />
											</>
										)}
										<Row className={styles.return_row}>
											<span className="title">Product Details</span>
											{data.status === ORDER_STATUS.DELIVERED || data.status === ORDER_STATUS.DTO_CANCELLED ? (
												<Button size="small" type="primary" onClick={() => setIsModalVisible(true)}>
													Schedule Return
												</Button>
											) : (
												data.status === ORDER_STATUS.DTO_PLACED && (
													<Popconfirm
														title="Are you sure to cancel this return?"
														onConfirm={cancelReturn}
														onCancel={() => null}
														okText="Yes"
														cancelText="No"
													>
														<Button size="small" type="primary">
															Cancel Return
														</Button>
													</Popconfirm>
												)
											)}
											<Modal
												title="Return Items"
												visible={isModalVisible}
												onCancel={() => setIsModalVisible(false)}
												footer={[
													<Button key="submit" type="primary" loading={loading} onClick={onReturnItem}>
														Submit
													</Button>
												]}
											>
												<table style={{ width: '100%' }}>
													<tr>
														<th>Title</th>
														<th>Qty to return</th>
													</tr>
													{data.items.map((item: any) => (
														<tr key={item.title}>
															<td>{item.title}</td>
															<td>
																<Input
																	type="number"
																	name={item.sku}
																	suffix={` / ${item.quantity}`}
																	defaultValue={0}
																	onChange={(e) => {
																		item.returnQuantity = Number(e.target.value) || 0;
																	}}
																/>
															</td>
														</tr>
													))}
												</table>
											</Modal>
										</Row>
										<Col span={24} className="sub">
											{data.items.map((v: any) => (
												<Row className="row4-row" key={v.id}>
													<Col span={24}>{`${v.title} (${v.quantity})`}</Col>
													<Col span={24}>
														<Row>
															<Col span={12}>
																<span>{`Rs ${parseFloat(v.finalPrice).toFixed(2)}`}</span>
															</Col>
															<Col span={12}>
																<span>{v.quantity}</span>
															</Col>
														</Row>
													</Col>
												</Row>
											))}
										</Col>
									</Row>

									{isCustomerFormVisible ? (
										<FormLayout
											formTitle={'Edit Customer Details'}
											onSave={(values: any) => onCustomerDetailsSubmit(values)}
											onClose={() => setIsCustomerFormVisible(false)}
											formFields={customerFormMemoized}
											initialValues={{
												firstName: data.customerAddress?.firstName,
												lastName: data.customerAddress?.lastName,
												email: data?.shippingEmail,
												phone: data?.shippingPhone || data?.shippingAddress?.phone || data?.customerAddress?.phone,
												addressLine1: data.customerAddress?.addressLine1,
												addressLine2: data.customerAddress?.addressLine2,
												city: data.customerAddress?.city,
												state: data.customerAddress?.state,
												pincode: data.customerAddress?.pincode
											}}
										/>
									) : (
										<>
											<Row className={styles.row4}>
												<Col span={24}>
													<Row>
														<Col span={18}>
															<span className="title">Customer Details</span>
														</Col>
														{(data.status === ORDER_STATUS.PLACED ||
															data.status === ORDER_STATUS.UNSERVICEABLE) && (
															<Col className={styles.icon} span={6}>
																<Button
																	type="primary"
																	size="small"
																	style={{ width: '60%' }}
																	onClick={(e) => {
																		e.stopPropagation();
																		setIsCustomerFormVisible(true);
																	}}
																>
																	Edit
																</Button>
															</Col>
														)}
													</Row>
												</Col>
												<Col span={24} className="sub">
													<Row className="row4-row">
														<Col span={12}>
															<span>{`${data.customerAddress?.firstName} ${data.customerAddress?.lastName}`}</span>
														</Col>
														<Col span={12} className="right-text ellipsis">
															<Icon component={() => phoneIcon} />{' '}
															<span>
																{data?.shippingPhone ||
																	data?.shippingAddress?.phone ||
																	data?.customerAddress?.phone}
															</span>
														</Col>
													</Row>
													<Row className="row4-row">
														<Col span={12}>
															<span>{`${data.customerAddress?.addressLine1}, ${data.customerAddress?.addressLine2}, ${data.customerAddress?.city}`}</span>
															<br />
															<span>Pincode: {data.customerAddress?.pincode}</span>
														</Col>
														<Col span={12} className="right-text ellipsis">
															<Icon component={() => envelopeIcon} />{' '}
															<Tooltip placement="topRight" title={data?.shippingEmail || '--'}>
																<span>{data?.shippingEmail || <span className="ml-2">-</span>}</span>
															</Tooltip>
														</Col>
													</Row>
												</Col>
											</Row>

											<Row className={styles.row4}>
												<Col span={24}>
													<Row>
														<Col span={24}>
															<span className="title">Shipping Package Type</span>
														</Col>
														{data.status === ORDER_STATUS.PLACED ? (
															<Col className={styles.icon} span={6}>
																<Select
																	defaultValue={data.shippingPackageCode}
																	onChange={(selectedCode) => updateOrderPackage(data.id, selectedCode)}
																>
																	{shippingPackageCodes?.map((code) => (
																		<Select.Option key={code}>{code}</Select.Option>
																	))}
																</Select>
																<Button
																	size="middle"
																	onClick={() => setIsPackageModalVisible(true)}
																	type="primary"
																>
																	+Add
																</Button>
															</Col>
														) : (
															<Col span={12} className="sub">
																<h6>{data.shippingPackageCode}</h6>
															</Col>
														)}
														<PackageModal
															setReloadCount={setReloadCount}
															setIsModalVisible={setIsPackageModalVisible}
															isModalVisible={isPackageModalVisible}
														/>
													</Row>
												</Col>
											</Row>
										</>
									)}
									{hyperLocalPartners.some((localData) => localData === data.deliveryPartnerId) && (
										<Suspense fallback={<div>Loading.....</div>}>
											<GoogleMapComponent orderId={orderId} />
										</Suspense>
									)}
								</div>

								<Row className={styles.row3}>
									{data?.status === ORDER_STATUS.PLACED && data?.couriorPartner?.length > 0 && (
										<>
											<strong className="mb-2">
												Select Courier Partner
												<InfoPopOver description="Will be used in case of shipping aggregators. Also, since the package dimension details are fetched from shipping package type, it is required to first select the correct shipping package type." />
											</strong>

											<SelectDropDown
												disabled={
													shippingPackageCodes?.length === 0 &&
													data?.couriorPartner?.[0]?.[`isPriceAndEddAvailabe`]
												}
												setData={setShipperIdOfAggregator}
												data={data?.couriorPartner}
											/>
										</>
									)}
									{data.status === ORDER_STATUS.PLACED && (
										<Col span={24} className="mt-3">
											<Button
												type="primary"
												size="middle"
												shape="round"
												onClick={onClickReadyToShip}
												loading={isReadyToShipButtonLoading}
											>
												READY TO SHIP
											</Button>
										</Col>
									)}

									{[ORDER_STATUS.READY_TO_SHIP, ORDER_STATUS.OUT_FOR_DELIVERY, ORDER_STATUS.IN_TRANSIT].includes(
										data.status
									) &&
										data.deliveryPartnerId === FulfillmentProviders.CLICKPOST && (
											<Col span={24} className="mt-3">
												<Button
													danger
													size="middle"
													shape="round"
													onClick={onCancelOrder}
													loading={isCancelOrderLoading}
												>
													CANCEL ORDER
												</Button>
											</Col>
										)}
									{showCancelOrderButton() && isAdminPanel && (
										<Col span={24} className="mt-3">
											<Popconfirm
												title="Are you sure you want to unblock Inventory?"
												okText="Yes"
												cancelText="No"
												onConfirm={handleUnBlockInventory}
											>
												<Button type="primary" danger size="middle" shape="round" loading={isCancelOrderLoading}>
													Unblock Inventry
												</Button>
											</Popconfirm>
										</Col>
									)}

									{isAdminPanel &&
										data.status === ORDER_STATUS.READY_TO_SHIP &&
										(data.shopType === SHOP_TYPE.MANUAL_FBW || data.shopType === SHOP_TYPE.MANUAL) && (
											<Col span={24} className="mt-3">
												{data?.isD2ROrder && data?.paymentMode === PAYMENT_METHOD.COD ? (
													<Popover
														style={{ position: 'relative' }}
														content={
															<Form layout="vertical" onFinish={markOrderAsDelivered}>
																<Form.Item
																	name={PAYMENT_METHOD.CREDIT}
																	label="Enter due date to convert COD order to credit?"
																>
																	<Input
																		min={dayjs().format('YYYY-MM-DD')}
																		max={dayjs().add(60, 'days').format('YYYY-MM-DD')}
																		type="date"
																		onChange={(event) => setDueDate(event.target.value)}
																		style={{ marginBottom: 10 }}
																	/>
																</Form.Item>
																<div style={{ position: 'absolute', bottom: 20, right: 20, gap: 10 }}>
																	<Button
																		type="primary"
																		size="middle"
																		shape="round"
																		htmlType="submit"
																		style={{ marginRight: 5 }}
																	>
																		Submit
																	</Button>
																</div>
															</Form>
														}
														//	title="Enter due date to convert cod order to credit [OPTIONAL]"
														trigger="click"
													>
														<Button
															type="primary"
															size="middle"
															shape="round"
															//onClick={markOrderAsDelivered}
															loading={isDeliveredButtonLoading}
														>
															Mark as Delivered
														</Button>
													</Popover>
												) : (
													<Button
														type="primary"
														size="middle"
														shape="round"
														onClick={markOrderAsDelivered}
														loading={isDeliveredButtonLoading}
													>
														Mark as Delivered
													</Button>
												)}
											</Col>
										)}

									{!isAdminPanel && data.status === ORDER_STATUS.READY_TO_SHIP && data.shopType === SHOP_TYPE.MANUAL_FBW && (
										<Col span={24} className="mt-3">
											<Button
												type="primary"
												size="middle"
												shape="round"
												onClick={markOrderAsDelivered}
												loading={isDeliveredButtonLoading}
											>
												Mark as Delivered
											</Button>
										</Col>
									)}

									{showCancelOrderButton() && (
										<Col span={24} className="mt-3">
											<Popover
												style={{ position: 'relative' }}
												content={
													<Form
														name="cancellationReasonForm"
														layout="vertical"
														onFinish={handleCancelOrder}
														autoComplete="off"
													>
														<Form.Item name={'canReason'} label="Enter reason for cancellation:">
															<Input
																type={'text'}
																maxLength={500}
																onChange={(e) => setCancellationReason(e.target.value)}
															/>
														</Form.Item>
														<Form.Item>
															<Button
																type="primary"
																size="middle"
																shape="round"
																htmlType="submit"
																style={{ marginRight: 5 }}
																loading={isCancelOrderButtonLoading}
															>
																Submit
															</Button>
														</Form.Item>
													</Form>
												}
												trigger="click"
											>
												<Button type="text" size="middle" shape="round" loading={isCancelOrderButtonLoading} danger>
													Cancel Order
												</Button>
											</Popover>
										</Col>
									)}
								</Row>

								<Typography.Title level={4}>Timeline</Typography.Title>
								<Row className={styles.row2} style={{ position: 'relative', zIndex: 0 }}>
									<Col span={12}>
										{collapsableForwardTrail.map((trailDetails) => (
											<Timeline key={trailDetails[0].id}>
												<OrderTrail
													key={trailDetails[0].id}
													trailDetails={trailDetails[0]}
													fetchOrderDetails={fetchOrderDetails}
													showInfoIcon={false}
													data={trailDetails}
												/>
											</Timeline>
										))}
									</Col>
									<Col span={12}>
										{collapsableReverseTrail?.length > 0 &&
											collapsableReverseTrail.map((trailDetails) => (
												<Timeline key={trailDetails[0].id}>
													<OrderTrail
														key={trailDetails[0].id}
														trailDetails={trailDetails[0]}
														fetchOrderDetails={fetchOrderDetails}
														showInfoIcon={false}
														data={trailDetails}
													/>
												</Timeline>
											))}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className={styles.row5}>
							{data?.isD2ROrder && (
								<Row style={{ width: '100%', marginBottom: '1rem' }}>
									<Col span={24}>
										<PrintCreditDebitNote
											orderDetails={data}
											type="Credit"
											disabled={!data?.creditDebitNotes?.length}
										/>
									</Col>
								</Row>
							)}
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<PrintLabelDialog orderDetails={data} />
								</Col>
								<Col span={12}>
									<PrintInvoiceDialog orderDetails={data} />
								</Col>
							</Row>
						</Row>
					</Col>
				) : (
					data === undefined && <div className="noDataDefaultComponent">Nothing to do here</div>
				)}
			</div>
		</InfoSlider>
	);
}
