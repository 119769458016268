import { Alert, Button, Divider, Form, Input, Modal, notification, Tooltip } from 'antd';
import { useState } from 'react';
import { baseApi } from '../../../../api';
import errorHandler from '../../../../utils/errorHandler';
import { getWSINMapFormat } from '../../../Catalog/constants';

interface ICreditDebitNoteModal {
	order: any;
	setIsCreditDebitNodeModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setReloadOrder: React.Dispatch<React.SetStateAction<number>>;
}

export const CreditDebitNoteModal = (props: ICreditDebitNoteModal) => {
	const { order, setIsCreditDebitNodeModalVisible, setReloadOrder } = props;

	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const handleOnFinish = async (value: any) => {
		try {
			setLoading(true);
			const { data } = await baseApi.post(`/wms/inventory/credit-debit/note/${order?.id}`, value);
			data?.status &&
				notification.success({
					message: data?.message
				});
			setIsCreditDebitNodeModalVisible(false);
			setReloadOrder((e) => e + 1);
		} catch (err) {
			errorHandler(err);
		} finally {
			setLoading(false);
		}
	};

	const getAvailableQuantity = (item: any) => {
		const x = order.dcnItems.filter((i: any) => i.sku === item.sku);
		const totalDcnQuantity = x.reduce((sum: number, i: any) => sum + i.quantity, 0);
		return item.quantity - totalDcnQuantity;
	};

	return (
		<>
			<Modal
				title="Fill Credit Debit Note details"
				visible={true}
				onCancel={() => setIsCreditDebitNodeModalVisible(false)}
				footer={[]}
				width={'90vw'}
			>
				<Form form={form} size="small" layout="vertical" onFinish={handleOnFinish}>
					<Alert message="Add Damaged / Expired Products (If any)" type="warning" />
					<table style={{ width: '100%', margin: '15px', fontSize: '12px' }}>
						<tr>
							{['Title', 'SKU', 'WSIN', 'Total Returns', 'Expired', 'Damaged'].map((e) => (
								<th>{e}</th>
							))}
						</tr>

						{order?.items.map((item: any) => {
							const availableQty = getAvailableQuantity(item);
							return (
								<tr key={item.id} style={{ verticalAlign: 'inherit' }}>
									<td>
										<Tooltip placement="top" title={item.title}>
											<p style={{ fontSize: '1.5vmin' }}>{item.title}</p>
										</Tooltip>
									</td>
									<td>
										<p style={{ fontSize: '1.5vmin' }}>{item.sku}</p>
									</td>
									<td>
										<p style={{ fontSize: '1.5vmin' }}>{getWSINMapFormat(item?.wsinMapping || {})}</p>
									</td>
									<td>
										<Form.Item name={[item.id, 'credit']} style={{ width: '85%' }}>
											<Input
												type="number"
												size="large"
												placeholder={`Out of ${item.quantity * 1}`}
												suffix={` / ${availableQty}`}
												disabled={availableQty === 0}
												max={item.quantity * 1}
												min={0}
												value={0}
											/>
										</Form.Item>
									</td>
									<td>
										<Form.Item name={[item.id, 'expired']} style={{ width: '85%' }}>
											<Input
												type="number"
												size="large"
												placeholder={`0`}
												suffix={` / ${availableQty}`}
												disabled={availableQty === 0}
												max={item.quantity * 1}
												min={0}
												value={0}
											/>
										</Form.Item>
									</td>
									<td>
										<Form.Item name={[item.id, 'damaged']} style={{ width: '85%' }}>
											<Input
												type="number"
												size="large"
												placeholder={`0`}
												suffix={` / ${availableQty}`}
												disabled={availableQty === 0}
												max={item.quantity * 1}
												min={0}
												value={0}
											/>
										</Form.Item>
									</td>
									<Divider />
								</tr>
							);
						})}
					</table>
					<Form.Item>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								type="primary"
								size="large"
								block
								htmlType="submit"
								loading={loading}
								style={{ position: 'sticky', top: '0px', width: '20rem' }}
							>
								Generate
							</Button>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};
